.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #fd6500; /* Blue */
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}