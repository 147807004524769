.App {
  background-color: 'white';
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}
/* Container from every page */
.Container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: rgb(236, 234, 234);
}
/*Parallax Image*/
.ParrallaxImage{
  background-image: url('https://www.mccrory.co.uk/training/wp-content/uploads/2018/10/slider3-1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-attachment: fixed;
  height: 40vh;
  filter: grayscale(0.7);
}
/* Full Screen Background Image */
.BackgroundImage {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter:blur(5px) grayscale(0.4);
}
/* Generall Adjustments */
a{
  text-decoration: none;
  color: unset;
}

@media only screen and (max-width: 1000px){
  .Container{
    height: auto;
  }
}
/* @media only screen and (min-height:1200px){
  .ParrallaxImage{
    display: none;
  }
} */
